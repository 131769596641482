.developer-new-section {
    padding: 120px 0;
    background: #EAF5FD;
    min-height: 960px;

    .developer-heading-content {
        padding-bottom: 40px;
        h2 {
            font-size: 40px;
            line-height: 150%;
            font-weight: 700;
            color: #040405;
            margin-bottom: 0;
            text-align: center;
        }
    }
    .developer-table {
        border: 2px solid rgba(0, 134, 230, 0.20);
        border-radius: 16px;
        background-color: #fff;
        overflow: hidden;
        ul {
            &:nth-child(2) {
                li {
                    &:first-child {
                        border-radius: 12px 0 0 0;
                    }
                }
            }
            &:nth-child(5) {
                li {
                    &:first-child {
                        border-radius: 0 0 0 12px;
                    }
                }
            }
        }

        .developer-table-list {
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;
            flex-wrap: wrap;
            margin: 0;
            padding-left: 0;
            li {
                width: 25%;
                padding: 20px 30px;
                list-style: none;
                font-size: 20px;
                line-height: 30px;
                font-weight: 600;
                color: #040405;
                margin: 0;
                height: 100%;
                border-left: 1px solid rgba(0, 134, 230, 0.20);
                &:first-child {
                    border: none;
                }

                .developer-list-box {
                    position: relative;
                    margin-bottom: 20px;
                    .next-project-profile {
                        width: 72px;
                        height: 72px;
                        border-radius: 12px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 12px;
                        border: 2px solid rgba(0, 134, 230, 0.07);
                        background: #fff;
                        position: relative;
                        z-index: 9;
                        img {
                            width: 40px;
                            height: 40px;
                        }
                    }
                    span {
                        width: 72px;
                        height: 72px;
                        border-radius: 12px;
                        border: 1px solid #fff;
                        opacity: 0.1;
                        background: linear-gradient(283deg, #0086E6 5.56%, #8DD0FF 113.66%);
                        position: absolute;
                        top: 0;
                        left: 0;
                        opacity: 0;
                        transition: 0.3s;
                    }
                }
                &:hover {
                    .developer-list-box {
                        .next-project-profile {
                            box-shadow: 0px 35.29644012451172px 23.349950790405273px 0px rgba(0, 0, 0, 0.04);
                        }
                        span {
                            opacity: 0.1;
                            top: 10px;
                            left: 10px;
                        }
                    }
                }
            }
            .developer-list-green {
                background: linear-gradient(317.72deg, #8ABC00 5.24%, #CCFA4E 137.29%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                .developer-list-box {
                    // .next-project-profile{
                    //     border: 2px solid var(--profile-border-light);
                    // }
                    // span{
                    //     background: var(--card-green-bg);
                    // }
                }
            }
            .developer-list-blue {
                background: linear-gradient(283deg, #0086E6 5.56%, #8DD0FF 113.66%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
        .developer-inline-list {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            margin: 0;
            padding-left: 0;
            li {
                width: 25%;
                padding: 28px 25px 28px 30px;
                list-style: none;
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                color: #040405;
                margin: 0;
                height: 100%;
                border-left: 1px solid rgba(0, 134, 230, 0.20);
                border-top: 1px solid rgba(0, 134, 230, 0.20);

                &:first-child {
                    // border-radius: 12px 0 0 0;
                    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.8) 5.56%, rgba(141, 208, 255, 0.7) 113.66%),
                    linear-gradient(283.13deg, rgba(0, 134, 230, 0.2) 5.56%, rgba(141, 208, 255, 0.2) 113.66%)   !important;
                }
                &:first-child,
                &:nth-child(4n + 1) {
                    border: none;
                }
                &:nth-child(4n + 1) {
                    background: linear-gradient(283.13deg, rgba(0, 134, 230, 0.8) 5.56%, rgba(141, 208, 255, 0.7) 113.66%),
                    linear-gradient(283.13deg, rgba(0, 134, 230, 0.2) 5.56%, rgba(141, 208, 255, 0.2) 113.66%);
                    color: #fff;
                    border-top: 1px solid rgba(0, 134, 230, 0.001);
                    font-size: 18px;
                    font-weight: 600;
                }
                &:nth-child(13) {
                    border-radius: 0 0 0 12px;
                }
                &:nth-child(4n + 17) {
                    background: unset;
                }

                .btn-secondary {
                    text-transform: uppercase;
                }
                .btn-green {
                    background: linear-gradient(317.72deg, #8ABC00 5.24%, #CCFA4E 137.29%);
                }
                // &:nth-child(-n + 4){
                //      background: linear-gradient(0deg, rgba(0, 134, 230, 0.09), rgba(0, 134, 230, 0.09)),
                //     linear-gradient(0deg, rgba(242, 249, 254, 0.2), rgba(242, 249, 254, 0.2));
                //  }
            }
            // &:last-child {
            //     li {
            //         &:first-child {
            //             background: unset !important;
            //         }
            //     }
            // }
        }
        .developer-bg-list {
            background: #F2F9FE;
            li {
                &:first-child {
                    opacity: 0.85;
                }
            }
        }
    }
}

.display-sm-block {
    display: none;
}

.technology-developer-section {
    .developer-table {
        .developer-table-list {
            li {
                width: 37.5%;
                &:first-child {
                    width: 25%;
                }
            }
        }
        .developer-inline-list {
            li {
                width: 37.5%;
                &:first-child {
                    width: 25%;
                }
            }
        }
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1025px) {
    .developer-new-section {
        min-height: 500px !important;
    }
    .container {
        max-width: 1000px !important;
    }
    .developer-new-section .developer-table .developer-table-list li {
        font-size: 16px;
    }
}

@media (max-width: 1400px) {
    .developer-new-section .developer-table .developer-inline-list li {
        padding: 15px;
        font-size: 14px;
        line-height: 20px;
        min-height: 80px;
        display: flex;
        align-items: center;
    }
    .developer-new-section .developer-table .developer-inline-list li:nth-child(4n + 1) {
        font-size: 16px;
        line-height: 20px;
    }
}

@media (max-width: 1024px) {
    .developer-new-section {
        padding: 80px 0;
        min-height: 660px;
    }
    .developer-new-section .developer-heading-content {
        padding-bottom: 32px;
    }
    .developer-new-section .developer-heading-content h2 {
        font-size: 32px;
    }
}

@media (max-width: 992px) {
    .developer-new-section {
        padding: 40px 0;
    }
    .developer-new-section .developer-heading-content h2 {
        font-size: 24px;
    }
    .developer-new-section .developer-heading-content {
        padding-bottom: 24px;
    }
    .developer-new-section .developer-table {
        padding-left: 0;
    }
    .developer-new-section .developer-table .developer-table-list li {
        padding: 12px 15px;
        font-size: 14px;
    }
    .developer-new-section .developer-table .developer-table-list li .developer-list-box .next-project-profile {
        width: 50px;
        height: 50px;
    }
    .developer-new-section .developer-table .developer-table-list li .developer-list-box span {
        width: 50px;
        height: 50px;
    }
    .developer-new-section .developer-table .developer-table-list li .developer-list-box .next-project-profile img {
        width: 28px;
        height: 28px;
    }
    .developer-new-section .developer-table .developer-table-list li .developer-list-box {
        margin-bottom: 14px;
    }
}

@media (max-width: 767px) {
    .display-sm-none {
        display: none;
    }
    .display-sm-block {
        display: block;
    }

    .developer-new-section .developer-table .developer-inline-list li:first-child,
    .developer-new-section .developer-table .developer-table-list li:first-child {
        width: 30%;
    }
    .developer-new-section .developer-table .developer-inline-list li {
        width: 70%;
    }
    .developer-new-section .developer-table .developer-inline-list li:nth-child(4n + 1) {
        font-size: 12px;
        font-weight: 500;
    }
    .developer-new-section .developer-table .developer-inline-list li {
        font-size: 12px;
    }
    .developer-new-section .developer-table .developer-inline-list li {
        min-height: 48px;
    }
    .developer-new-section .acordion-developer-table.developer-table {
        width: 100%;
        border: none;
    }
    .developer-new-section .acordion-developer-table.developer-table .developer-table-list {
        width: 100%;
    }
    .developer-new-section .acordion-developer-table.developer-table .developer-table-list li {
        width: 100%;
        padding: 0;
        font-size: 16px;
    }
    .developer-new-section .accordion .accordion-item {
        border: 2px solid rgba(0, 134, 230, 0.20);
        border-radius: 12px;
        background-color: #fff;
        overflow: hidden;
    }
    .developer-new-section .accordion .accordion-item .accordion-button {
        background-color: #fff;
        border: none;
        box-shadow: none;
    }
    .developer-new-section .accordion .accordion-item .accordion-button:after {
        display: none;
    }
    .developer-new-section .accordion .accordion-item .accordion-body {
        padding: 0;
    }
    .developer-new-section .developer-table ul:nth-child(2) li:first-child {
        border-radius: 0;
    }
    .developer-new-section .developer-table {
        border: none;
        overflow: unset;
    }
    .developer-new-section .developer-table .developer-table-list li {
        width: 70%;
    }

    .developer-new-section .accordion .accordion-item {
        margin-bottom: 20px;
    }
}
